import React from 'react'
import { Input } from 'antd';
const InputForm = (props) => {
    return (
        <div className={props.className}>
            <label htmlFor={props.label}>{props.label}</label>
            <div className="an_input">
                <Input value={props.value} onBlur={(e) => props.checkPONumber()} onChange={(e) => props.onChangePo(e.target.value)} placeholder={props.pls} />
            </div>
            {
                !props.isUnique?
                <div>
                    <label htmlFor={props.label} style={{color:'red'}}>Po Number is not unique</label>
                </div>
                :
                null
            }
        </div>
    )
}
export default InputForm;